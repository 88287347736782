import Vue from 'vue'
import VueQuillEditor from 'vue-quill-editor'

Vue.use(VueQuillEditor)

function contentWatchOverride(newVal) {
  if (this.quill) {
    if (newVal && newVal !== this._content) {
      this._content = newVal
      const delta = this.quill.clipboard.convert(newVal)
      this.quill.setContents(delta)
    } else if (!newVal) {
      this.quill.setText('')
    }
  }
}

const Base = Vue.options.components['quill-editor']
const CustomQuillEditor = Base.extend({
  beforeCreate() {
    Vue.set(this.$options.watch, 'content', contentWatchOverride.bind(this))
    Vue.set(this.$options.watch, 'value', contentWatchOverride.bind(this))
  },
})

Vue.component('custom-quill-editor', CustomQuillEditor)
