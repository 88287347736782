import Vue from 'vue'
import {
  BVConfigPlugin,
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormTextarea,
  BFormDatepicker,
  BFormCheckboxGroup,
  BFormRadio,
  BFormRadioGroup,
  BPagination,
} from 'bootstrap-vue'

Vue.use(BVConfigPlugin, {
  BTable: {
    hover: true,
    showEmpty: true,
    emptyText: '일치하는 데이터가 없습니다.',
  },

  BFormDatepicker: {
    locale: 'ko-KR',
    todayButton: true,
    resetButton: true,
    closeButton: true,
    hideHeader: false,
    dateFormatOptions: { year: 'numeric', month: '2-digit', day: '2-digit' },
  },
})

Vue.component('BCard', BCard)
Vue.component('BRow', BRow)
Vue.component('BCol', BCol)
Vue.component('BButton', BButton)
Vue.component('BTable', BTable)
Vue.component('BForm', BForm)
Vue.component('BFormGroup', BFormGroup)
Vue.component('BFormInput', BFormInput)
Vue.component('BFormCheckbox', BFormCheckbox)
Vue.component('BFormTextarea', BFormTextarea)
Vue.component('BFormDatepicker', BFormDatepicker)
Vue.component('BFormCheckboxGroup', BFormCheckboxGroup)
Vue.component('BFormRadio', BFormRadio)
Vue.component('BFormRadioGroup', BFormRadioGroup)
Vue.component('BPagination', BPagination)
