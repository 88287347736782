import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes Import
import { canNavigate } from '@/libs/acl/routeProtection'
import {
  isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser, isClientRole,
} from '@/auth/utils'

import apps from './routes/apps'
import dashboard from './routes/dashboard'
import pages from './routes/pages'
import clients from './routes/clients'
// Routes Import End

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'dashboard-analytics' } },
    ...apps,
    ...dashboard,
    ...pages,
    ...clients,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach(async (to, _, next) => {
  if (to.meta.nonAuth === true) return next()

  const isLoggedIn = await isUserLoggedIn()

  if (!isLoggedIn && (to.name === 'client-login' || isClientRole())) return next({ name: 'client-login' })

  if (!isLoggedIn) return next({ name: 'auth-login' })

  const userData = getUserData()

  if (!canNavigate(to, userData)) {
    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
