export default [
  {
    path: '/dashboard/analytics',
    name: 'dashboard-analytics',
    component: () => import('@/views/Home.vue'),
    meta: {
      roleLevel: 7,
    },
  },
]
