export default [

  // *===============================================---*
  // *------------------제약사 페이지--------------------*
  // *===============================================---*

  {
    path: '/clients/home',
    name: 'clients-home',
    component: () => import('@/views/clients/Home.vue'),
    meta: {
      roleLevel: 6,
    },
  },
  {
    path: '/clients/statistics/durginfo-stast-view/druginfo-stast-list',
    name: 'clients-druginfo-stast-list',
    component: () => import('@/views/clients/statistics/durginfo-stast-view/DrugInfoStatsList.vue'),
    meta: {
      roleLevel: 6,
    },
  },
  {
    path: '/clients/statistics/livedi-stast-view/livedi-stast-list',
    name: 'clients-livedi-stast-list',
    component: () => import('@/views/clients/statistics/livedi-stast-view/LiveDiStatsList.vue'),
    meta: {
      roleLevel: 6,
    },
  },
  {
    path: '/clients/statistics/druglanding-stast-view/druglanding-stast-list',
    name: 'clients-druglanding-stast-list',
    component: () => import('@/views/clients/statistics/druglanding-stast-view/DrugLandingStatsList.vue'),
    meta: {
      roleLevel: 6,
    },
  },
  {
    path: '/clients/statistics/suervey-stast-view/suervey-stast-list',
    name: 'clients-survey-stast-list',
    component: () => import('@/views/clients/statistics/survey-stast-view/SurveyStatsList.vue'),
    meta: {
      roleLevel: 6,
    },
  },
  {
    path: '/clients/statistics/seminar-stast-view/seminar-stast-list',
    name: 'clients-seminar-stast-list',
    component: () => import('@/views/clients/statistics/seminar-stast-view/SeminarStatsList.vue'),
    meta: {
      roleLevel: 6,
    },
  },

]
