import Vue from 'vue'

Vue.directive('input-type', {
  bind(element, binding) {
    if (!binding || !binding.value) return

    element.addEventListener('input', () => {
      const el = element
      switch (binding.value) {
        case 'number':
          el.value = el.value.replace(/[^0-9]/gi, '')
          break

        case 'text':
          el.value = el.value.replace(/[0-9]/gi, '')
          break

        default:
          break
      }
    })
  },
})
