export const useResponseRouting = (axiosIns, router) => {
  axiosIns.interceptors.response.use(
    // response => Promise.resolve(response),
    response => response,
    error => {
      const { response: { status } } = error

      if (status === 401 || status === 405) {
        axiosIns.get('/fa/auth/is-login').then(isLoginRes => {
          const { data } = isLoginRes
          if (!data || data.isLogin === false) {
            // 로그인 페이지로 이동
            localStorage.removeItem('userData')
            router.replace({ name: 'auth-login' })
          }
        })
      }
      return Promise.reject(error)
    },
  )
}

export const test = () => { }
