/**
 * Global Filter Mixin
 * @namespace: '$'
 * */
import dayjs from 'dayjs'

export const customFilter = {
  filters: {
    $numberFormatter(value = '', formatting = '') {
      const rtnValue = ` ${value}`
      return String(rtnValue).replace(/\B(?=(\d{3})+(?!\d))/g, ',') + formatting
    },

    $dateFormatter(value = '', formatting = 'YYYY-MM-DD') {
      return value ? dayjs(new Date(value)).format(formatting) : ''
    },
  },
}

export default customFilter
