export default [
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'full',
      nonAuth: true,
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/client/login',
    name: 'client-login',
    component: () => import('@/views/clients/Login.vue'),
    meta: {
      layout: 'full',
      nonAuth: true,
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
      nonAuth: true,
    },
  },
  {
    path: '/pages/miscellaneous/not-authorized',
    name: 'misc-not-authorized',
    component: () => import('@/views/pages/miscellaneous/NotAuthorized.vue'),
    meta: {
      layout: 'full',
      nonAuth: true,
    },
  },
]
